import React, { useEffect }  from 'react';
import { FcFolder } from 'react-icons/fc';
import { AiTwotoneFileText } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { formatDate, formatSize, getItemsInCurrentPath } from '../../util/fileFunctions';
import { RiFolderDownloadLine } from 'react-icons/ri';
import { HiDownload } from 'react-icons/hi';
import { GoArrowLeft } from 'react-icons/go';

const FileList = ({ fileData, currentPath, handlePathChange, handleDownload, setFileCount }) => {
    const itemsInCurrentPath = getItemsInCurrentPath(fileData, currentPath);
    const handleGoUp = () =>{
        handlePathChange(currentPath.slice(0, currentPath.length -1));
    };

    useEffect(()=>{
        setFileCount(itemsInCurrentPath.length);
    },[itemsInCurrentPath.length]);

    return (
        <div className="file-list">
            
            <div className="file-list-table">
                <div className="file-list-header" >
                    <div>File Name</div>
                    <div>Size</div>
                    <div>Last Modified</div>
                    <div style={{textAlign:'center'}}>Actions</div>
                </div>
                <div className='file-list-container'>
                    {currentPath.length > 1 && <div key={'log-file-list'} className="file-list-item">
                        <div className="file-name"  onClick={() => handleGoUp()} style={{cursor:'pointer', color:'#5050bb', fontFamily:'Open Sans'}}>
                            <GoArrowLeft size={22}/> back
                        </div>
                    </div>}
                    {itemsInCurrentPath.length > 0 ? (
                        itemsInCurrentPath.map(item => (
                            <div key={item.name} className="file-list-item">
                                <div className="file-name"  onDoubleClick={() => item.isDir && handlePathChange([...currentPath, item.name])}>
                                    {item.isDir ? <FcFolder  size={24} /> : <AiTwotoneFileText size={20} />}
                                    <span>{item.name}</span>
                                </div>
                                <div className="file-size">{item.isDir ? '-' : formatSize(item.sizeBytes)}</div>
                                <div className="file-modified">{formatDate(item.modifiedTime)}</div>
                                <div style={{ cursor: 'pointer', textAlign:'center' }} onClick={() => handleDownload(item)} data-testid='handleDownload'>
                                    {item.isDir ? <RiFolderDownloadLine size={22}/> : <HiDownload size={22}/>}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="file-list-empty">No items in this folder.</div>
                    )}
                </div>
                
            </div>
        </div>
    );
};

FileList.propTypes = {
    fileData:PropTypes.array, 
    handlePathChange:PropTypes.func, 
    currentPath:PropTypes.array,
    handleDownload: PropTypes.func,
    setFileCount:PropTypes.func
};

export default FileList;