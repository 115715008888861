import React from 'react';
import {  MdHome  } from 'react-icons/md';
import PropTypes from 'prop-types';
import { MdChevronRight } from 'react-icons/md';

const FileBreadcrumbs = ({currentPath, handlePathChange}) => {
    const handleBreadcrumbClick = index => {
        handlePathChange(currentPath.slice(0, index + 1));
    };
    return (
        <div className="file-breadcrumbs">
            {currentPath.map((part, index) => (
                <span key={index} onClick={() => handleBreadcrumbClick(index)} className='file-breadcrumbs-wrap' data-testid='handleBreadcrumbs'>
                    {index === 0 ? <MdHome size={26}/> : null}
                    {part}{index < currentPath.length - 1 ? <MdChevronRight size={20}/> : ''}
                </span>
            ))}
        </div>
    );
};

FileBreadcrumbs.propTypes = {
    handlePathChange:PropTypes.func, 
    currentPath:PropTypes.array,
};

export default FileBreadcrumbs;